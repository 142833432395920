<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/mixins/list";
export default {
  mixins: [list],

  data() {
    const vm = this;
    return {
      nav: [{ name: "人员网格作业" }],
      //【钩子字段】
      primarykey:"gridId",//主键：为列表数据的唯一性标识字段名，若不指定，默认取"id"。主要用来实现多选/单选操作
      //【钩子字段】
      common_getBidNameAndIdList: [1, 0, null],//下拉框for ayl-table
      table: {
        api: vm.$api_hw.personnelWork_getGridList,
        query: {
          bidId: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "网格名称"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "网格名称",
            key: "gridName",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onDetail.bind(this, ctx.row)
                    }
                  },
                  ctx.row.gridName || "- -"
                )
              ]);
            }
          },
          {
            title: "所属标段",
            key: "bidName",
            // width: "100px"
          },
          {
            title: "网格类型",
            key: "gridTypeDes",
            width: "100px"
          },
          {
            title: "网格负责人",
            key: "gridManager",
            // width: "120px"
          },
          {
            title: "联系方式",
            key: "contactPhone",
            width: "150px"
          },
          {
            title: "应排班人数",
            key: "scheduleWorkerNum",
            width: "120px"
          },
          {
            title: "实配人数",
            key: "dutyRealNum",
            width: "120px"
          },
          {
            title: "变更类型",
            key: "changeTypeDes",
            width: "100px"
          },
          {
            title: "状态",
            key: "checkStatusDes",
            width: "100px"
          },
          {
            title: "审核意见",
            key: "checkResult",
            width: "100px"
          },
          {
            title: "操作",
            width: "150px",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
